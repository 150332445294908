import clsx from 'clsx'
import React from 'react'
import { ImageType } from '../../templates/Generic'
import { breakpoints } from '../consts'
import {
  Spacing,
  useMediaQuery,
  useSpacing,
  TextSizeType,
  TextComponent,
  TextComponentProps,
  useTextSize,
  Markdown,
  Button,
  type ButtonProps,
} from '..'

export interface NewFeaturesProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  image: ImageType
  cardList: {
    title: string
    content: string
    contentBlurb: {
      id: string
      content: string
    }[]
    cardImage?: ImageType
    text: TextSizeType
    button: ButtonProps[]
  }[]
}

const buildNewFeatureCards = (cardList: NewFeaturesProps['cardList']) =>
  cardList.map(card => (
    <div key={card.title} className="py-8">
      {card.cardImage ? (
        <img
          className="h-[40px] w-[40px]"
          src={card.cardImage?.url || ''}
          alt={
            card.cardImage?.alt ||
            card.cardImage?.filename ||
            `${card.title} icon`
          }
        />
      ) : (
        <div className="bg-light-mesh-gradient h-[40px] w-[40px] rounded-full"></div>
      )}
      <h3 className="py-[10.26px] font-MaisonNeueExtended text-xl font-semibold text-black">
        {card.title}
      </h3>
      <div
        className={clsx(
          'font-normal text-black/[.6]',
          useTextSize({ textSize: card.text }),
          {
            'mb-8': card.button.length > 0,
          }
        )}
      >
        <Markdown markdown={card.content} />
        {card.contentBlurb.map(blurb => (
          <Markdown markdown={blurb.content} />
        ))}
      </div>
      {card.button.map((button, index) => (
        <Button
          key={button.label}
          linkTo={button.linkTo}
          showArrow
          btnType={button.btnType}
          size={button.size}
          className={`${index > 0 && 'ml-4'} font-semibold`}
        >
          {button.label}
        </Button>
      ))}
    </div>
  ))

export const NewFeatures = ({ data }: { data: NewFeaturesProps }) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('md'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        'grid h-min max-w-page px-4 py-8 md:mx-auto md:grid-cols-[1fr_1.5fr] md:px-0 md:pr-24',
        spacing
      )}
    >
      <img
        className="justify-self-center md:w-full md:pr-12"
        src={data.image?.url || ''}
        alt={
          data.image?.alt ||
          data.image?.filename ||
          `${data.titleSection[0].title}`
        }
      />
      <div className="pt-12">
        <TextComponent data={data.titleSection} />
        <div className="grid grid-cols-2 gap-8">
          {buildNewFeatureCards(data.cardList)}
        </div>
      </div>
    </div>
  )
}
