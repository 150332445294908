import React from 'react'
import { format } from 'date-fns'
import { Button } from '../Button'
import { BlogPostCard } from './BlogPostCard'

type NextUpProps = {
  relatedArticles?: Array<Queries.DatoCmsBlogPost & { to?: string }>
}

export const NextUp = ({ relatedArticles }: NextUpProps) => (
  <div className="mx-auto w-full max-w-7xl py-16 px-4 md:px-24">
    <h2 className="mx-auto max-w-7xl text-5xl font-bold text-grey-900">
      Next Up
    </h2>

    <div className="mt-16 grid grid-cols-1 gap-14 lg:grid-cols-3 lg:gap-8">
      {relatedArticles?.map(article => (
        <BlogPostCard key={article?.id} article={article} />
      ))}
    </div>
  </div>
)
