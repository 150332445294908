import clsx from 'clsx'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '../../components'
import { breakpoints } from '../consts'
import {
  AnnouncementBanner,
  TopBarButtons,
  TopBarLogo,
  TopBarMenu,
  TopBarMobileMenu,
  TopBarMobileSubmenu,
} from './Components'
import { TopNav } from './Components/TopBarMenu/Components'

export const TopBar = ({ hasHeader }: { hasHeader?: boolean }) => {
  const [atTop, setAtTop] = useState(true)

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset === 0) {
        setAtTop(true)
      } else {
        setAtTop(false)
      }
    }
  })

  // Get button info
  const {
    datoCmsTopBar: {
      topBarButtons,
      announcementBanner,
      newTopbarMenu,
      topBarMenu,
    },
  } = useStaticQuery(graphql`
    {
      datoCmsTopBar {
        newTopbarMenu {
          id
          title
          linkto
          subheader
          button {
            label
            linkTo
            id
          }
          sublink {
            label
            linkTo
            id
          }
          navElements {
            topNavColumnWidth
            topNavMenuBlock {
              ... on DatoCmsNavigationFeature {
                id
                model {
                  apiKey
                }
                header
                featureTitle
                featureSubtitle
                featureImage {
                  url
                  alt
                  originalId
                  filename
                }
                linkto
              }
              ... on DatoCmsColumnSubsection {
                id
                model {
                  apiKey
                }
                header
                content {
                  linkto
                  title
                  subheadContent
                  id
                }
              }
            }
          }
        }
        announcementBanner {
          announcement
          bannerColor
          linkto
        }
        topBarButtons {
          ... on DatoCmsButton {
            linkTo
            label
          }
          ... on DatoCmsSublink {
            title
            linkTo
          }
        }
      }
    }
  `)
  // Mobile menu state control
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [submenu, setSubmenu] = useState('')
  // Determines whether the main mobile menu will slide in from the right
  const [animate, setAnimate] = useState(false)
  // Determine whether to show TopNav
  const [show, setShow] = useState('')

  const { matches: renderMobile } = useMediaQuery(breakpoints.down('lg'))

  return (
    <>
      <div className="fixed top-0 z-20">
        {announcementBanner.length > 0 && (
          <AnnouncementBanner data={announcementBanner} />
        )}
        <div
          className={clsx(
            `fixed w-full text-gray-900 lg:px-8 lg:py-[1.625rem] xl:px-24`,
            {
              'to-transparent bg-gradient-to-b from-white': atTop && !show,
              'bg-white shadow-standard': !atTop || show,
            }
          )}
        >
          <div
            className={`grid w-full max-w-page grid-cols-1 md:mx-auto lg:grid-cols-[1fr_1fr]`}
          >
            {renderMobile ? (
              <>
                {showMobileMenu && (
                  <TopBarMobileSubmenu
                    menuItems={newTopbarMenu}
                    show={showMobileMenu}
                    setShow={setShowMobileMenu}
                    submenu={submenu}
                    setSubmenu={setSubmenu}
                    topBarButtons={topBarButtons}
                  />
                )}
                <TopBarMobileMenu
                  show={showMobileMenu}
                  setShow={setShowMobileMenu}
                />
              </>
            ) : (
              <>
                <div className="flex-cols flex w-max">
                  <TopBarLogo />
                  <TopBarMenu
                    menuItems={newTopbarMenu}
                    setShow={setShow}
                    show={show}
                  />
                </div>
                <TopBarButtons topBarButtons={topBarButtons} />
              </>
            )}
          </div>
          {!renderMobile && (
            <TopNav
              show={show}
              setShow={setShow}
              data={newTopbarMenu.find(
                // Send the data for the item clicked on
                (item: any) =>
                  item.title === show && item.navElements.length > 0
              )}
            />
          )}
        </div>
      </div>
    </>
  )
}
