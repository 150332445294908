import type { PageProps } from 'gatsby'
import type { Spacing } from '../hooks'

import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import qs from 'query-string'
import { Grid } from '../Grid'
import { useSpacing } from '../hooks'
import { PaginationClicker } from '../Quotations/Components/PaginationClicker'
import { Button } from '../Button'

export interface AppStoreListProps {
  data: Queries.DatoCmsAppStoreList
  location: PageProps['location']
}

export const AppStoreList = ({ data, location }: AppStoreListProps) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const [appsByTag, setAppsByTag] = useState<Queries.DatoCmsAppStoreListing[]>(
    []
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(10)
  const pageSize = data?.appsPerPage || 10

  const {
    allDatoCmsAppStoreListing: { nodes: appListings },
  } = useStaticQuery(graphql`
    {
      allDatoCmsAppStoreListing(sort: { header: { companyName: ASC } }) {
        nodes {
          id
          url
          header {
            ...datoCmsAppStoreHeaderComponent
          }
        }
      }
    }
  `)

  useEffect(() => {
    setPages(Math.ceil(appsByTag.length / pageSize))
  }, [appsByTag])

  useEffect(() => {
    // reset page scroll when the pagination changes
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [currentPage])

  useEffect(() => {
    const { tag } = qs.parse(location.search)
    const regex = new RegExp(tag as string, 'i')
    setAppsByTag(
      appListings.filter((app: Queries.DatoCmsAppStoreListing) => {
        // if an app tag nav item is active
        if (tag && tag !== 'all') {
          return !!app?.header?.[0]?.tag?.find(t => t?.name?.match(regex))
        }

        return true
      })
    )
  }, [appListings, location])

  return (
    <div
      key={data.id}
      className={clsx('max-w-page px-4 md:mx-auto md:px-24', spacing)}
    >
      <Grid {...(data.layout?.[0] as any)}>
        {appsByTag
          ?.slice(pageSize * (currentPage - 1), pageSize * currentPage)
          .map((app: Queries.DatoCmsAppStoreListing) => {
            const header = app.header?.[0]
            if (!header) return null

            return (
              <div
                key={app.id}
                className="grid grid-rows-[auto_1fr_auto] gap-8 rounded-3xl p-8 shadow-standard"
              >
                <img
                  src={header.companyLogo?.[0]?.responsiveImage?.url as string}
                  alt={header.companyLogo?.[0]?.responsiveImage?.alt as string}
                  className="h-24 w-24"
                />
                <div className="flex flex-col gap-2">
                  <h3 className="text-xl font-semibold">
                    {header.companyName}
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {header.tag?.map(t => (
                      <span className="rounded-full border border-gray-400 px-4 py-2 text-xs text-gray-600">
                        {t?.name}
                      </span>
                    ))}
                  </div>
                </div>
                <Button linkTo={app.url as string} showArrow>
                  Learn More
                </Button>
              </div>
            )
          })}

        {pages > 1 && data.showPagination && (
          <div className="col-span-full">
            <PaginationClicker
              currentNumber={currentPage}
              setCurrentNumber={setCurrentPage}
              totalItems={pages}
            />
          </div>
        )}
      </Grid>
    </div>
  )
}
