import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../../../../hooks'
import { TopNavTitle, TopNavMenu } from './Components'
import clsx from 'clsx'

export const TopNav = ({
  show,
  data,
  setShow,
}: {
  show: string
  data: any
  setShow: React.Dispatch<React.SetStateAction<string>>
}) => {
  const wrapperRef = useRef(null)
  const [fadeIn, setFadeIn] = useState(false)
  useOnClickOutside(wrapperRef, () => setShow(''))

  useEffect(() => {
    if (!show) setFadeIn(false)

    if (show) {
      setTimeout(() => {
        setFadeIn(true)
      }, 1)
    }
  }, [show])

  return data?.navElements?.length > 0 ? (
    <div
      ref={wrapperRef}
      className={clsx(
        'left-0 mt-6 w-full pb-16 pt-8 lg:px-8 xl:px-24',
        'border-t border-solid border-gray-400 bg-white shadow-standard-bottom-only',
        'transition-opacity duration-300',
        {
          'opacity-0': !fadeIn,
          'opacity-1': fadeIn,
          fixed: show,
          hidden: !show,
        }
      )}
    >
      <TopNavTitle
        title={data.title}
        content={data.subheader}
        buttonTitle={data.button.length > 0 ? `${data.button[0].label}` : ''}
        buttonLink={data.button[0]?.linkTo || ''}
        link={data.sublink[0]?.linkTo || ''}
        linkTitle={data.sublink[0]?.label ? `${data.sublink[0].label}` : ''}
      />
      <TopNavMenu data={data.navElements} />
    </div>
  ) : null
}
