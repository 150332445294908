/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

interface SeoData {
  site: Queries.Site
  allDatoCmsLandingPage: { nodes: Array<Queries.DatoCmsLandingPage> }
  allDatoCmsBlogPost: { nodes: Array<Queries.DatoCmsBlogPost> }
}

export const Seo = ({ title }: { title?: string }) => {
  const { pathname } = useLocation()
  const {
    site,
    allDatoCmsLandingPage,
    allDatoCmsBlogPost,
  }: SeoData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      allDatoCmsLandingPage {
        nodes {
          url
          seo {
            title
            description
            twitterCard
            image {
              url
            }
          }
        }
      }
      allDatoCmsBlogPost {
        nodes {
          slug
          title
          briefDescription
          image {
            url
          }
        }
      }
    }
  `)

  const isBlogPage = pathname.includes('/posts/')

  const blogPage = isBlogPage
    ? allDatoCmsBlogPost.nodes.find(
        blogPost => blogPost.slug && pathname.includes(blogPost.slug)
      )
    : null

  const landingPage =
    !isBlogPage &&
    allDatoCmsLandingPage.nodes.find(
      page => `${page.url?.replace('https://provenance.io', '')}/` === pathname
    )
  const isLandingPage = !!landingPage

  if (isLandingPage) {
    return (
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{landingPage.seo?.title}</title>
        <meta
          name="title"
          property="og:title"
          content={landingPage.seo?.title || ''}
        />
        <meta
          name="description"
          property="og:description"
          content={landingPage.seo?.description || ''}
        />
        <meta
          name="image"
          property="og:image"
          content={landingPage.seo?.image?.url || ''}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://provenance.io${pathname}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={landingPage.seo?.title || ''} />
        <meta
          name="twitter:description"
          content={landingPage.seo?.description || ''}
        />
        <meta
          name="twitter:image"
          content={landingPage.seo?.image?.url || ''}
        />
      </Helmet>
    )
  } else if (isBlogPage) {
    return (
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{blogPage?.title || 'Learn | Provenance Blockchain'}</title>
        <meta
          name="title"
          property="og:title"
          content={blogPage?.title || ''}
        />
        <meta
          name="description"
          property="og:description"
          content={blogPage?.briefDescription || ''}
        />
        <meta
          name="image"
          property="og:image"
          content={blogPage?.image?.url || ''}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://provenance.io${pathname}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={blogPage?.blogAuthor?.name || ''}
        />
        <meta name="twitter:title" content={blogPage?.title || ''} />
        <meta
          name="twitter:description"
          content={blogPage?.briefDescription || ''}
        />
        <meta name="twitter:image" content={blogPage?.image?.url || ''} />
      </Helmet>
    )
  } else {
    return (
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{site?.siteMetadata?.title}</title>
        <meta
          name="title"
          property="og:title"
          content={site?.siteMetadata?.title || ''}
        />
        <meta
          name="description"
          property="og:description"
          content={site?.siteMetadata?.description || ''}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://provenance.io${pathname}`} />
        <meta
          name="twitter:creator"
          content={site?.siteMetadata?.author || ''}
        />
        <meta name="twitter:title" content={site?.siteMetadata?.title || ''} />
        <meta
          name="twitter:description"
          content={site?.siteMetadata?.description || ''}
        />
      </Helmet>
    )
  }
}
