import React from 'react'
import { Button, type ButtonProps } from '../Button'

type ButtonArray = Array<ButtonProps & { id: string }>

export const ButtonGroup = ({
  buttons,
  darkMode,
}: {
  buttons: ButtonArray
  darkMode: boolean
}) => (
  <div className="flex flex-row justify-center gap-2 sm:gap-8 lg:justify-start">
    {buttons.map((singleButton, index) => (
      <Button
        key={singleButton.id}
        className="font-semibold"
        linkTo={singleButton.linkTo}
        showArrow
        btnType={
          index === 0
            ? singleButton.btnType || darkMode
              ? 'light'
              : 'primary'
            : 'outline'
        }
        size={singleButton.size || 'medium'}
      >
        {singleButton.label}
      </Button>
    ))}
  </div>
)
