type SizeOption = '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'

type Sizes = {
  [key in SizeOption]: number
}

export const sizes: Sizes = {
  '2xl': 1536, // [ 1536px - ∞ ]
  xl: 1279, // [ 1279px - 1535px ]
  lg: 1024, // [ 1024px - 1280px ]
  md: 767, // [ 768px - 1023px ]
  sm: 640, // [ 640px - 767px ]
  xs: 0, // [ 0 - 639px ]
}

/** Exact size and above/greater, eg: 1536 - ∞  */
const up = (size: SizeOption) => `(min-width: ${sizes[size]}px)`
// Exact size and below/less, eg: 1279 - 0 px
const down = (size: SizeOption) => `(max-width: ${sizes[size]}px)`
// Exactly one size, eg: 600px
const only = (size: SizeOption) =>
  `(min-width: ${sizes[size]}px) and (max-width: ${sizes[size]}px)`
// Between two sizes, eg: 600px-1280px
const between = (min: SizeOption, max: SizeOption) =>
  `(min-width: ${sizes[min]}px) and (max-width: ${sizes[max] - 1}px)`

/** Returns breakpoint information for screen sizes.
 * - up: Exact size and above/greater, eg: 1536 - ∞
 * - down: Exact size and below/less, eg: 1280 - 0px
 * - only: Exactly one size, eg: 600px
 * - between: Between two sizes, eg: 640px-1280px
 */
export const breakpoints = {
  up,
  down,
  only,
  between,
}
