import React from 'react'
import { Spacing, useSpacing } from '../hooks'
import { ImageProps } from '../../templates/Generic'
import { SmartLink } from '../Link'
import clsx from 'clsx'

interface SocialLink {
  id: string
  linkTo: string
  image: {
    url: string
  }
}

export type AppStoreHeaderProps = {
  category: {
    id: string
    option: string
  }[]
  companyLogo: ImageProps[]
  companyName: string
  description: string
  socialLinks: SocialLink[]
  spacing: Spacing[]
  tag: {
    id: string
    name: string
  }[]
}[]

export const AppStoreHeader = ({ data }: { data: AppStoreHeaderProps }) => {
  const header = data[0]
  const spacing = useSpacing(header.spacing?.[0] as Spacing)
  return (
    <header
      className={clsx(
        'grid grid-cols-1 gap-6 lg:grid-cols-[auto_1fr]',
        'max-w-page px-4 md:mx-auto md:px-24',
        spacing
      )}
    >
      {header.companyLogo[0].responsiveImage.url && (
        <div className="flex h-24 w-24 items-center justify-center">
          <img
            src={header.companyLogo[0].responsiveImage.url}
            alt={header.companyName}
          />
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-8">
          <h1 className="text-5xl font-bold text-blue-900">
            {header.companyName}
          </h1>
          <div className="flex items-center gap-6">
            {header.socialLinks.map((link: SocialLink) => (
              <SmartLink
                key={link.id}
                linkTo={link.linkTo}
                className="flex h-6 w-6 items-center justify-center"
              >
                <img src={link.image.url} alt={link.id} />
              </SmartLink>
            ))}
          </div>
        </div>
        <p className="text-blue-700">{header.description}</p>
        <div className="flex flex-wrap gap-4">
          {header.tag.map(({ name }) => (
            <span className="rounded-full border border-gray-400 px-4 py-2 text-gray-600">
              {name}
            </span>
          ))}
        </div>
      </div>
    </header>
  )
}
