import React, { useState } from 'react'
import type { ImageType } from '../../templates/Generic'
import { getCookie } from '../../utils'
import validator from 'email-validator'
import clsx from 'clsx'
import {
  type ButtonProps,
  breakpoints,
  Button,
  Spacing,
  useMediaQuery,
  useSpacing,
} from '..'

export interface CTABarComponentProps {
  formId: string
  anchorTag: string
  button: ButtonProps[]
  title: string
  subtext: string
  darkMode: boolean
  successMessage: string
  image: ImageType
  spacing: Spacing[]
}

export const CTABarComponent = ({ data }: { data: CTABarComponentProps }) => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('md'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)

  const formId = data.formId
  const thankYouMessage = data.successMessage

  const handleSubmit = async (evt: any) => {
    evt.preventDefault()
    if (!validator.validate(email)) return setStatus('invalid')
    setStatus('pending')

    const ipRes = await fetch('https://jsonip.com/')
    const { ip: ipAddress } = await ipRes.json()

    const hutk = getCookie('hubspotutk')

    const loc = location?.href

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/22440300/${formId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [{ name: 'email', value: email }],
          context: {
            hutk,
            pageUri: loc,
            // Return page name as the last index of the url
            pageName: loc.substring(loc.lastIndexOf('/') + 1),
            ipAddress,
          },
        }),
      }
    )

    if (res.ok && res.status < 400) setStatus('completed')
    else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'
  const invalid = status === 'invalid'

  const subscribeBar = () => (
    <>
      {completed ? (
        <div
          className={clsx(`text-center font-bold`, {
            'text-white': data.darkMode,
            'text-neutral-600': !data.darkMode,
          })}
        >
          {thankYouMessage}
        </div>
      ) : (
        <>
          {failed && (
            <div className={`mb-2 text-red-500`}>
              Something went wrong, please try again.
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className={`${location} m-0 flex flex-col gap-[7px]`}
            noValidate
          >
            <div className="flex items-center justify-center text-base">
              <input
                type="email"
                className={clsx(
                  `
                border-transparent mb-0
                w-[100%] rounded-[40px] border border-solid px-4 py-1 placeholder:text-neutral-600 md:w-[50%]
                md:px-8 md:py-4
                `,
                  {
                    'border-red-500': invalid,
                    'text-red-500': invalid,
                  }
                )}
                disabled={pending}
                placeholder="Email Address"
                onChange={e => {
                  setEmail(e.target.value)
                  setStatus('')
                }}
                value={email}
              />
              <button
                className={clsx(
                  `ml-[-82px] rounded-[40px] px-4 py-1 md:ml-[-116px] md:px-8 md:py-4 md:font-bold`,
                  {
                    'bg-yellow-400 text-neutral-600': data.darkMode,
                    'bg-black text-white': !data.darkMode,
                  }
                )}
              >
                Submit
              </button>
            </div>
          </form>
          {invalid && (
            <div className={`mt-2 text-center text-red-500`}>
              Please enter a valid email address.
            </div>
          )}
        </>
      )}
    </>
  )

  const CTAText = ({
    title,
    subtext,
    className,
  }: {
    title: string
    subtext: string
    className?: string
  }) => (
    <div className={className} id={data.anchorTag}>
      <div
        className={clsx(
          `break-words text-center font-MaisonNeueExtended text-5xl font-semibold`,
          {
            'text-neutral-600': !data.darkMode,
            'text-white': data.darkMode,
          }
        )}
      >
        {title}
      </div>
      <div
        className={clsx(`pb-12 pt-4 text-center text-base font-normal`, {
          'text-neutral-600': !data.darkMode,
          'text-white': data.darkMode,
        })}
      >
        {subtext}
      </div>
    </div>
  )

  return data.image ? (
    <div
      className={`w-[100vw] bg-cover bg-center ${spacing}`}
      style={{
        backgroundImage: `url(${data.image.url as string})`,
      }}
    >
      <div className="flex max-w-page flex-col justify-center px-4 py-24 md:mx-auto md:px-24 xl:h-[550px]">
        <CTAText title={data.title} subtext={data.subtext} />
        {subscribeBar()}
      </div>
    </div>
  ) : (
    <div className={`max-w-page px-4 py-12 md:mx-auto md:px-24 ${spacing}`}>
      <div
        className={clsx(`rounded-[24px] px-8 py-24`, {
          'bg-light-mesh-gradient': !data.darkMode,
          'bg-dark-mesh-gradient': data.darkMode,
        })}
      >
        <CTAText title={data.title} subtext={data.subtext} />
        {data.formId ? (
          subscribeBar()
        ) : (
          <div className="flex w-full justify-center">
            <Button
              lg={!renderMobile}
              showArrow
              btnType={
                data.button[0].btnType || data.darkMode ? 'light' : 'primary'
              }
              size={data.button[0].size}
              linkTo={data.button[0].linkTo}
            >
              {data.button[0].label}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
