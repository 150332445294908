import React from 'react'
import { Button, Sublink } from '../../../../../../../components'

export const TopNavTitle = ({
  title,
  content,
  buttonTitle,
  buttonLink,
  link,
  linkTitle,
}: {
  title: string
  content?: string
  buttonTitle?: string
  buttonLink?: string
  link?: string
  linkTitle?: string
}) => (
  <div className="flex flex-col gap-8 py-[0.625rem] lg:mb-8 lg:flex-row lg:items-center lg:justify-between lg:gap-0">
    <div className="flex flex-col gap-8 lg:flex-row lg:items-center">
      <h2 className="font-MaisonNeueExtended text-[1.5rem] font-bold leading-8">
        {title}
      </h2>
      {content && <p className="w-[250px] text-gray-600">{content}</p>}
    </div>
    <div className="flex items-center gap-4">
      {buttonTitle && buttonLink && (
        <Button showArrow linkTo={buttonLink}>
          {buttonTitle}
        </Button>
      )}
      {link && linkTitle && (
        <Sublink
          linkTo={link}
          title={linkTitle}
          className="font-semibold text-blue-700"
          arrowClassname="text-blue-700"
        />
      )}
    </div>
  </div>
)
