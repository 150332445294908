import React, { useState, useEffect } from 'react'
import { ImageType } from '../../templates/Generic'
import { Button, type ButtonProps } from '../Button'
import { PaginationClicker } from './Components/PaginationClicker'
import { Spacing, useMediaQuery, useSpacing } from '../hooks'
import clsx from 'clsx'

export interface QuotationsProps {
  spacing: Spacing[]
  anchorTag: string
  quotationCardList: {
    button: ButtonProps[]
    headshotImage: ImageType
    name: string
    quote: string
    title: string
    backgroundImage?: ImageType
    darkMode: boolean
  }[]
}

const buildQuoteCard = ({
  quote,
  shift,
}: {
  quote: QuotationsProps['quotationCardList'][0]
  shift: number
}) => (
  <div
    id={quote.quote}
    key={quote.quote}
    style={{
      backgroundImage: `${
        quote.backgroundImage?.url
          ? `url(${quote.backgroundImage.url})`
          : "linear-gradient(0deg, rgba(1, 39, 85, 0.7), rgba(1, 39, 85, 0.7)), url('https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8NXx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=60')"
      }`,
      backgroundSize: 'cover',
      transition: '1s',
      left: `${shift}px`,
    }}
    className="relative rounded-[40px] p-8 shadow-standard md:p-16"
  >
    <div className="relative flex h-full flex-col justify-between">
      <div>
        <img
          className="max-w-[96px] rounded-full"
          src={quote.headshotImage.url as string}
          alt={
            quote.headshotImage.alt ||
            quote.headshotImage.filename ||
            `${quote.name} headshot`
          }
        />
        <div
          className={clsx(
            `pb-12 pt-[27.85px] text-2xl font-light md:pb-20 md:text-4xl`,
            {
              'text-white': quote.darkMode || !quote.backgroundImage?.url,
              'text-gray-900': !quote.darkMode,
            }
          )}
        >
          {quote.quote}
        </div>
      </div>
      <div className="items-center justify-between md:flex md:flex-row">
        <div>
          <div
            className={clsx(`pb-2 text-xl font-bold md:text-3xl`, {
              'text-white': quote.darkMode || !quote.backgroundImage?.url,
              'text-gray-900': !quote.darkMode,
            })}
          >
            {quote.name}
          </div>
          <div
            className={clsx(`pb-6 text-xl font-light md:pb-0`, {
              'text-white': quote.darkMode || !quote.backgroundImage?.url,
              'text-gray-900': !quote.darkMode,
            })}
          >
            {quote.title}
          </div>
        </div>
        {quote.button.length > 0 && (
          <Button
            showArrow
            linkTo={quote.button[0].linkTo}
            btnType={quote.button[0].btnType}
            size={quote.button[0].size}
          >
            {quote.button[0].label}
          </Button>
        )}
      </div>
    </div>
  </div>
)

export const Quotations = ({ data }: { data: QuotationsProps }) => {
  const [currentNumber, setCurrentNumber] = useState(1)
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const [quoteWidth, setQuoteWidth] = useState(
    document.getElementById(data.quotationCardList[0].quote)?.offsetWidth
  )
  // The idea is to dynamically determine the div length holding each quote,
  // then shift left or right by the div length + 16px (since gap is at 32 px)
  // to display the desired quote
  useEffect(() => {
    setQuoteWidth(
      document.getElementById(data.quotationCardList[0].quote)?.offsetWidth
    )
    window.addEventListener('resize', () =>
      setQuoteWidth(
        document.getElementById(data.quotationCardList[0].quote)?.offsetWidth
      )
    )
  })
  return (
    <div
      className={`max-w-page justify-center md:mx-auto ${spacing}`}
      id={data.anchorTag}
    >
      <div className="grid grid-cols-[repeat(4,_90vw)] gap-8 overflow-clip px-4 py-16 md:grid-cols-[repeat(4,_80vw)] md:px-24 xl:grid-cols-[repeat(4,_1100px)]">
        {data.quotationCardList.map(quote =>
          buildQuoteCard({
            quote,
            shift: (Number(quoteWidth) + 32) * (currentNumber - 1) * -1,
          })
        )}
      </div>
      {data.quotationCardList.length > 1 && (
        <PaginationClicker
          currentNumber={currentNumber}
          totalItems={data.quotationCardList.length}
          setCurrentNumber={setCurrentNumber}
        />
      )}
    </div>
  )
}
