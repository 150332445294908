import React, { useState } from 'react'
import { TopBarLogo } from './TopBarLogo'
// @ts-ignore
import leftCaret from '../../../images/left_caret.png'
import {
  TopNavTitle,
  TopNavMenu,
} from './TopBarMenu/Components/TopNav/Components'
import clsx from 'clsx'

export const TopBarMobileSubmenuNav = ({
  setSubmenu,
  data,
}: {
  setSubmenu: React.Dispatch<React.SetStateAction<string>>
  data: any
}) => {
  const [slideRight, setSlideRight] = useState(false)
  const timeout = (delay: number) => new Promise(res => setTimeout(res, delay))

  return (
    <div
      className={clsx(
        `fixed z-30 h-[100vh] w-full animate-slide-left overflow-x-hidden overflow-y-scroll`,
        {
          'animate-slide-away-right': slideRight,
        }
      )}
    >
      <div className="mb-16 grid grid-cols-1 justify-center gap-8 bg-white px-8 pb-16 pt-[1.625rem]">
        <div className="z-20 flex w-full items-center justify-between">
          <TopBarLogo />
          <div>
            <img
              className="cursor-pointer"
              src={leftCaret}
              alt="Close Mobile Top Nav Menu"
              onClick={async () => {
                // This sequence starts sliding the menu,
                // waits 200ms, then resets the submenu
                // on the topnav
                setSlideRight(true)
                await timeout(200)
                setSubmenu('')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1">
          <TopNavTitle
            title={data.title}
            content={data.subheader}
            buttonTitle={
              data.button.length > 0 ? `${data.button[0].label}` : ''
            }
            buttonLink={data.button[0]?.linkTo}
            link={data?.sublink[0]?.linkTo || ''}
            linkTitle={data.sublink[0]?.label ? `${data.sublink[0].label}` : ''}
          />
        </div>
        <div className="w-full">
          <TopNavMenu data={data.navElements} />
        </div>
      </div>
    </div>
  )
}
