import React from 'react'
import { TopBarMenuItem } from './Components'

export const TopBarMenu = ({
  menuItems,
  setShow,
  show,
}: {
  menuItems: any
  setShow: React.Dispatch<React.SetStateAction<string>>
  show: string
}) => (
  <div className={`flex w-max justify-items-center gap-8`}>
    {menuItems.map((item: any) => (
      <TopBarMenuItem
        key={item.id}
        title={item.title}
        showDropdown={show === item.title}
        isDropdown={item.navElements.length > 0}
        onClick={
          item.navElements.length > 0
            ? () => setShow(show == item.title ? '' : item.title)
            : undefined
        }
        linkTo={item.navElements.length === 0 && item.linkto}
      />
    ))}
  </div>
)
