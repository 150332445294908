import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { SmartLink } from '../../Link'
import { useMediaQuery } from '../../hooks'
import { breakpoints } from '../../consts'

export const TopBarLogo = () => {
  const {
    datoCmsTopBar: { topBarLogo, topBarSmLogo },
  } = useStaticQuery(graphql`
    {
      datoCmsTopBar {
        topBarLogo {
          image {
            url
          }
          linkTo
        }
        topBarSmLogo {
          image {
            url
          }
          linkTo
        }
      }
    }
  `)

  const { matches: largeLogo } = useMediaQuery(breakpoints.between('lg', 'xl'))

  const linkTo = largeLogo ? topBarSmLogo[0].linkTo : topBarLogo[0].linkTo
  const img = largeLogo ? topBarSmLogo[0].image : topBarLogo[0].image

  return (
    <SmartLink linkTo={linkTo}>
      <img
        className="mr-12 max-h-12 xl:mr-[4.5rem]"
        src={img.url}
        alt={img.alt || img.filename || 'Provenance Logo icon'}
      />
    </SmartLink>
  )
}
