import React from 'react'

export const TopNavMenuSectionHeader = ({
  sectionHeader,
  children,
  gridColumns = 1,
}: {
  sectionHeader?: string
  children?: JSX.Element | JSX.Element[]
  gridColumns?: number
}) => (
  <div className={`col-span-full`}>
    {sectionHeader && (
      <p className="mb-4 border-b border-grey-300 text-xs text-blue-700">
        {sectionHeader}
      </p>
    )}
    <div className={`grid grid-cols-2 lg:grid-cols-${gridColumns} gap-7`}>
      {children}
    </div>
  </div>
)
