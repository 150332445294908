import React from 'react'
import { TopBarButtons, TopBarLogo, TopBarMobileSubmenuNav } from '.'
// @ts-ignore
import close from '../../../images/Close.png'
import { graphql, useStaticQuery } from 'gatsby'
import { SmartLink } from '../../Link'
import clsx from 'clsx'

export const TopBarMobileSubmenu = ({
  menuItems,
  show,
  setShow,
  submenu,
  setSubmenu,
  topBarButtons,
}: {
  menuItems: any
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  submenu: string
  setSubmenu: React.Dispatch<React.SetStateAction<string>>
  topBarButtons: any
}) => (
  <>
    <div className="fixed z-20 mb-16 h-[100vh] w-full animate-slide-left overflow-x-hidden overflow-y-scroll bg-white px-8 pb-16 pt-[1.625rem]">
      <div className="grid w-full grid-cols-1 justify-center gap-8">
        <div className="flex w-full items-center justify-between">
          <TopBarLogo />
          <div>
            <img
              className="cursor-pointer"
              src={close}
              alt="Close Mobile Menu"
              onClick={() => {
                setShow(false)
              }}
            />
          </div>
        </div>
        {menuItems.map((item: any) => (
          <div
            key={item.id}
            className="flex cursor-pointer gap-1 text-[20px] font-medium"
            onClick={
              item.navElements.length > 0
                ? () => setSubmenu(show == item.title ? '' : item.title)
                : undefined
            }
          >
            {item.navElements.length > 0 ? (
              <>
                <div>{item.title}</div>
                <div>{item.navElements.length > 0 && '>'}</div>{' '}
              </>
            ) : (
              <SmartLink linkTo={item.linkto || ''}>
                <div>{item.title}</div>
                <div>{item.navElements.length > 0 && '>'}</div>
              </SmartLink>
            )}
          </div>
        ))}
        <div className="justify-center">
          <TopBarButtons topBarButtons={topBarButtons} />
        </div>
      </div>
    </div>
    {submenu && (
      <TopBarMobileSubmenuNav
        setSubmenu={setSubmenu}
        data={menuItems.find(
          // Send the data for the item clicked on
          (item: any) => item.title === submenu && item.navElements.length > 0
        )}
      />
    )}
  </>
)
