import clsx from 'clsx'
import React, { Fragment } from 'react'
import { Button, type ButtonProps } from '../Button'
import Lottie from 'react-lottie-player'
// @ts-ignore
import defaultMovieBackground from '../../images/HeaderAnimationLoop.mp4'
import { useMediaQuery } from '../hooks'
import { breakpoints } from '../consts'

export type HeaderDataProps = {
  anchorTag: string
  darkimage: boolean
  largeHeader: boolean
  image?: {
    url: string
  }
  title?: string
  subtitle?: string
  subtitleSize?: string
  button: ButtonProps[]
  lottieFile: string
}[]

export const Header = ({ data }: { data: HeaderDataProps }) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('lg'))
  return (
    <>
      {data.map(
        item =>
          item.title && (
            <Fragment key={item.title}>
              {item.lottieFile || !item.image?.url ? (
                <div
                  className="grid grid-cols-1 grid-rows-1"
                  id={item.anchorTag}
                >
                  {item.lottieFile ? (
                    <div className="col-span-full col-start-1 row-span-full row-start-1 flex flex-col items-center justify-center">
                      <Lottie
                        className={clsx(`w-full`, {
                          'h-[700px]':
                            item.largeHeader && item.button.length > 0,
                          'h-[600px]':
                            item.largeHeader && item.button.length === 0,
                          'h-[500px]': !item.largeHeader,
                        })}
                        loop
                        animationData={JSON.parse(item.lottieFile)}
                        play
                        rendererSettings={{
                          preserveAspectRatio: 'xMidYMid slice',
                        }}
                      />
                    </div>
                  ) : (
                    <div className="col-span-full col-start-1 row-span-full row-start-1 flex h-[100%] flex-col items-center justify-center">
                      <video
                        autoPlay
                        muted
                        playsInline
                        loop
                        id="video"
                        className={clsx(`w-full`, {
                          'max-h-[800px] w-full object-cover': !renderMobile,
                          'h-[700px] object-cover': renderMobile,
                        })}
                      >
                        <source src={defaultMovieBackground} type="video/mp4" />
                      </video>
                    </div>
                  )}
                  <div
                    key={item.title}
                    className={`
                    z-10
                    col-span-full col-start-1 row-span-full row-start-1
                    w-full max-w-page
                    self-center break-words px-4
                    md:mx-auto md:px-8
                    `}
                  >
                    <h1
                      className={clsx(`mb-4 font-MaisonNeueExtended text-4xl`, {
                        'font-bold md:text-6xl md:font-semibold xl:w-[800px] xl:text-9xl':
                          item.largeHeader,
                        'font-bold md:text-5xl': !item.largeHeader,
                        'text-white': item.darkimage,
                        'text-blue-900': !item.darkimage,
                      })}
                    >
                      {item.title}
                    </h1>
                    <h2
                      className={clsx(`mb-8 max-w-[800px]`, {
                        'text-white': item.darkimage,
                        'text-blue-700': !item.darkimage,
                        [`${item.subtitleSize}`]: item.subtitleSize,
                        'text-base': !item.subtitleSize,
                      })}
                    >
                      {item.subtitle}
                    </h2>
                    {item.button.length > 0 && item?.button[0]?.label && (
                      <Button
                        showArrow
                        linkTo={item.button[0].linkTo}
                        btnType={
                          item.button[0].btnType || item.darkimage
                            ? 'light'
                            : 'primary'
                        }
                        size={item.button[0].size || 'medium'}
                      >
                        {item.button[0].label}
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    id={item.anchorTag}
                    key={item.title}
                    className={clsx(
                      `break-words bg-cover bg-center bg-no-repeat px-4 py-[200px] md:px-8 lg:pl-24`
                    )}
                    style={{
                      backgroundImage: `url(${item.image?.url})`,
                    }}
                  >
                    <div className="max-w-page md:mx-auto">
                      <div
                        className={clsx(
                          `mb-4 font-MaisonNeueExtended text-4xl`,
                          {
                            'font-bold md:w-[800px] md:text-9xl md:font-semibold':
                              item.largeHeader,
                            'font-bold md:text-5xl': !item.largeHeader,
                            'text-white': item.darkimage,
                            'text-blue-900': !item.darkimage,
                          }
                        )}
                      >
                        {item.title}
                      </div>
                      <div
                        className={clsx(`mb-8 max-w-[800px]`, {
                          'text-white': item.darkimage,
                          'text-blue-700': !item.darkimage,
                          [`${item.subtitleSize}`]: item.subtitleSize,
                          'text-base': !item.subtitleSize,
                        })}
                      >
                        {item.subtitle}
                      </div>
                      {item.button.length > 0 && item.button[0].label && (
                        <Button
                          showArrow
                          linkTo={item.button[0].linkTo}
                          btnType={
                            item.button[0].btnType || item.darkimage
                              ? 'light'
                              : 'primary'
                          }
                          size={item.button[0].size || 'medium'}
                        >
                          {item.button[0].label}
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Fragment>
          )
      )}
    </>
  )
}
