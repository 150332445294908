import React from 'react'
import clsx from 'clsx'
import type { ImageType } from '../../templates/Generic'
import type { ButtonProps, TextSizeType, VideoType } from '..'
import { Spacing, useSpacing, useTextSize } from '..'
import { Label } from './Label'
import { Title } from './Title'
import { Subhead } from './Subhead'
import { Content } from './Content'
import { ButtonGroup } from './ButtonGroup'
import { ImageOrVideo } from './ImageOrVideo'

type ButtonArray = Array<ButtonProps & { id: string }>

export interface TextAndContentProps {
  section: {
    button: ButtonArray
    content: { content: string; id: string }[]
    image: ImageType
    video: VideoType
    listLabel: string
    subhead: string
    label: string
    title: string
    text: TextSizeType
  }[]
  anchorTag: string
  columns: string
  imageFirst: boolean
  imageWithTitle: boolean
  darkmode: boolean
  lightmode: boolean
  sizing: 'half' | 'thirdLeft' | 'thirdRight'
  gapSize: string
  spacing: Spacing[]
}

export const TextAndContent = ({ data }: { data: TextAndContentProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const labelProps = { label: data.section[0].label, darkMode: data.darkmode }
  const titleProps = { title: data.section[0].title, darkMode: data.darkmode }
  const subheadProps = {
    subhead: data.section[0].subhead,
    darkMode: data.darkmode,
  }

  return (
    <div
      className={clsx(
        `grid max-w-page grid-cols-1 items-center justify-center gap-${
          data.gapSize || '16'
        } px-4 py-16 md:mx-auto lg:px-24`,
        { 'lg:grid-cols-2': data.columns === '2' && data.sizing === 'half' },
        {
          'lg:grid-cols-[33%_67%]':
            data.columns === '2' && data.sizing === 'thirdLeft',
        },
        {
          'lg:grid-cols-[67%_33%]':
            data.columns === '2' && data.sizing === 'thirdRight',
        },
        { 'bg-blue-900': data.darkmode },
        { 'bg-yellow-400': data.lightmode && !data.darkmode },
        spacing
      )}
      id={data.anchorTag}
    >
      <div className="grid">
        <Label {...labelProps} />
        <Title {...titleProps} />
        <Subhead {...subheadProps} />

        {/* The image goes here if it IS with the title */}
        {(data.section[0].image || data.section[0].video) &&
          data.imageWithTitle && (
            <ImageOrVideo
              data={data}
              className={clsx('mt-16 justify-self-center', {
                'col-start-1 row-start-1 mb-16 mt-0': data.imageFirst,
                'mb-12': !data.imageFirst && data.columns === '1',
              })}
            />
          )}

        {/* Content goes here if the image is not with the title */}
        {!data.imageWithTitle && (
          <>
            <div className="py-8">
              {data.section[0].content.map((item, index) => (
                <Content
                  key={item.id}
                  content={item.content}
                  darkMode={data.darkmode}
                  textSize={useTextSize({ textSize: data.section[0].text })}
                />
              ))}
            </div>

            <ButtonGroup
              buttons={data.section[0].button}
              darkMode={data.darkmode}
            />
          </>
        )}
      </div>

      {/* Image should show here if it's not with the title */}
      {(data.section[0].image || data.section[0].video) &&
        !data.imageWithTitle && (
          <ImageOrVideo
            data={data}
            className={clsx('justify-self-center', {
              'col-start-1 row-start-1': data.imageFirst,
            })}
          />
        )}

      {/* Content should show here if the image is with the title */}
      {data.imageWithTitle && (
        <div>
          <div className="pb-12">
            {data.section[0].content.map((point, index) => (
              <Content
                content={point.content}
                darkMode={data.darkmode}
                listNum={index + 1}
                listLabel={data.section[0].listLabel}
                textSize={useTextSize({ textSize: data.section[0].text })}
              />
            ))}
          </div>
          <ButtonGroup
            buttons={data.section[0].button}
            darkMode={data.darkmode}
          />
        </div>
      )}
    </div>
  )
}
