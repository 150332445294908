import React from 'react'
import { SmartLink } from '../../../../../../../../Link'

export const TopNavMenuItemHeader = ({
  header,
  linkTo = '',
}: {
  header: string
  linkTo?: string
}) => (
  <div className="mb-0 flex gap-1 text-base lg:mb-1.5">
    <SmartLink linkTo={linkTo || ''}>{header}</SmartLink>
    <div className="font-bold text-blue-700">{`>`}</div>
  </div>
)
