import React from 'react'
// @ts-ignore
import downCaret from '../../../../../images/down_caret.png'
import { SmartLink } from '../../../../Link'

export const TopBarMenuItem = ({
  title,
  isDropdown,
  showDropdown = false,
  onClick,
  linkTo = '',
}: {
  title: string
  isDropdown?: boolean
  showDropdown?: boolean
  onClick?: void | (() => void)
  linkTo?: string
}) => (
  <>
    <div
      className="flex items-center gap-[0.5px] font-bold text-gray-900"
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <div
        className={`relative inline-block cursor-pointer border-0 bg-none outline-none
        before:absolute before:-left-[7.5%] before:w-0 before:content-['']
        ${
          !showDropdown &&
          'hover:before:bottom-[-4px] hover:before:left-[25%] hover:before:h-px hover:before:w-[50%] hover:before:border-b-2 hover:before:border-blue-900 hover:before:duration-[0.25s]'
        }
        ${
          showDropdown &&
          "before:absolute before:bottom-[-4px] before:left-0 before:h-px before:w-[115%] before:border-b-2 before:border-blue-600 before:duration-[0.25s] before:content-['']"
        }
      `}
      >
        <SmartLink
          linkTo={linkTo || ''}
          className="hover:text-inherit font-MaisonNeueExtended no-underline"
        >
          {title}
        </SmartLink>
      </div>
      {isDropdown && (
        <img
          className="h-[4px] w-[9px] object-contain"
          src={downCaret}
          alt="down caret"
        />
      )}
    </div>
  </>
)
