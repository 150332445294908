import React from 'react'
import {
  TopNavFeatureItem,
  TopNavMenuItem,
  TopNavMenuSectionHeader,
} from './Components'

type Content = {
  id: string
  linkto: string
  title: string
  subheadContent: string
}

type ColumnSubsection = {
  content: Content[]
}

export type NavigationFeature = {
  featureTitle: string
  featureSubtitle: string
  featureImage: {
    url: string
    alt: string
    originalId: string
    filename: string
  }
  linkto: string
}

type TopNavMenuBlock = {
  id: string
  model: {
    apiKey: string
  }
  header: string
} & ColumnSubsection &
  NavigationFeature

type TopNavProps = {
  data: {
    topNavColumnWidth: string
    topNavMenuBlock: TopNavMenuBlock[]
  }[]
}

export const TopNavMenu = ({ data }: TopNavProps) => {
  const renderNavSection = (block: TopNavMenuBlock) => {
    switch (block.model.apiKey) {
      case 'navigation_feature':
        return <TopNavFeatureItem item={block} />
      case 'column_subsection':
        return block.content.map((content: Content) => (
          <TopNavMenuItem
            key={content.id}
            header={content.title}
            content={content.subheadContent}
            linkTo={content.linkto}
          />
        ))
    }
  }

  return (
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-5">
      {data.map(item => (
        <div className={`grid gap-8 lg:col-span-${item.topNavColumnWidth}`}>
          {item.topNavMenuBlock.map((block: TopNavMenuBlock) => (
            <TopNavMenuSectionHeader
              key={block.header}
              sectionHeader={block.header}
              gridColumns={Number(item.topNavColumnWidth)}
            >
              {renderNavSection(block)}
            </TopNavMenuSectionHeader>
          ))}
        </div>
      ))}
    </div>
  )
}
