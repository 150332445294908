import React from 'react'
import {
  AppStoreHeader,
  AppStoreHeaderProps,
  Footer,
  Header,
  HeaderDataProps,
  TopBar,
} from '..'

export const PageChrome = ({
  header,
  appHeader,
  className = 'grid grid-cols-1 mx-auto',
  children,
}: {
  header?: HeaderDataProps
  appHeader?: AppStoreHeaderProps
  className?: string
  children: any
}) => {
  const hasAppHeader = !!appHeader?.find((data: any) => data.companyName)
  const hasLandingHeader = !!header?.find((data: any) => data.title)

  return (
    <div className={className}>
      <TopBar hasHeader={hasAppHeader || hasLandingHeader} />
      {hasLandingHeader && <Header data={header as HeaderDataProps} />}
      {hasAppHeader && (
        <AppStoreHeader data={appHeader as AppStoreHeaderProps} />
      )}
      <main>{children}</main>
      <Footer />
    </div>
  )
}
