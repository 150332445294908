import React from 'react'
import { NavigationFeature } from '../TopNavMenu'
import { SmartLink } from '../../../../../../../../Link'

export const TopNavFeatureItem = ({ item }: { item: NavigationFeature }) => (
  <SmartLink
    linkTo={item.linkto}
    className="col-span-full flex flex-col gap-4 no-underline"
  >
    <div>
      <header className="font-MaisonNeueExtended font-semibold text-gray-900">
        {item.featureTitle}
      </header>
      <p className="text-sm text-gray-600">{item.featureSubtitle}</p>
    </div>
    <img src={item.featureImage.url} alt={item.featureImage.alt} />
    <div className="flex-cols flex cursor-pointer items-center gap-1 font-MaisonNeueExtended text-blue-700">
      Learn More <span className="text-xl font-bold text-blue-700">{`>`}</span>
    </div>
  </SmartLink>
)
