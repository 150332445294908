import React from 'react'
import { TopBarLogo } from '.'
// @ts-ignore
import hamburger from '../../../images/Hamburger.png'

export const TopBarMobileMenu = ({
  show,
  setShow,
}: {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}) => (
  <div className={`flex w-full items-center justify-between px-4 py-6`}>
    <TopBarLogo />
    <div>
      <img
        className="cursor-pointer"
        src={hamburger}
        alt={'Hamburger Menu Icon'}
        onClick={() => setShow(!show)}
      />
    </div>
  </div>
)
