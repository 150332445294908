import React from 'react'
import { TopNavMenuItemContent, TopNavMenuItemHeader } from '.'

export const TopNavMenuItem = ({
  header,
  content,
  linkTo,
}: {
  header: string
  content: string
  linkTo?: string
}) => (
  <div>
    <TopNavMenuItemHeader header={header} linkTo={linkTo} />
    <TopNavMenuItemContent content={content} />
  </div>
)
