import React, { useState } from 'react'
import clsx from 'clsx'
import { BsChevronCompactDown } from 'react-icons/bs'
import { TextComponent, useSpacing } from '..'
import type { Spacing, TextComponentProps } from '..'
import type { ButtonProps } from '../../templates/Generic'

export interface FaqSimpleProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  simpleFaqList: {
    id: string
    title: string
    copy: string
  }[]
  button: ButtonProps[]
}

export const FaqSimple = ({ data }: { data: FaqSimpleProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const [openId, setOpenId] = useState<string | undefined>(
    data.simpleFaqList[0].id
  )
  return (
    <div
      className={clsx(
        'grid max-w-page grid-cols-1 items-center gap-8 px-4 md:mx-auto md:px-24',
        spacing
      )}
    >
      {data.titleSection.length > 0 && (
        <TextComponent data={data.titleSection} />
      )}
      {data.simpleFaqList.map(faq => (
        <div
          key={faq.id}
          className="grid cursor-pointer grid-cols-[auto_80px] rounded-3xl bg-blue-100 p-8"
          onClick={() => setOpenId(id => (id === faq.id ? undefined : faq.id))}
        >
          <div>
            <header
              className={clsx(
                'font-MaisonNeueExtended text-lg font-bold text-grey-900 transition-[margin] md:text-xl',
                {
                  'mb-[18px]': openId === faq.id,
                }
              )}
            >
              {faq.title}
            </header>
            <p
              className={clsx(
                'overflow-hidden text-base text-grey-600 transition-[max-height]',
                {
                  'max-h-screen': openId === faq.id,
                  'max-h-0': openId !== faq.id,
                }
              )}
            >
              {faq.copy}
            </p>
          </div>
          <div className="flex justify-end pt-[6px]">
            <BsChevronCompactDown
              className={clsx(
                'w-4 stroke-2 text-grey-900 motion-safe:transition-transform',
                {
                  'rotate-180': openId === faq.id,
                }
              )}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
